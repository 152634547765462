
import { addMetaTags } from '~/site-config'

export default {
  name: 'Home',
  head() {
    return {
      meta: addMetaTags({
        image: require('@/assets/gd_logo_text.svg'),
      }),
    }
  },
  beforeRouteUpdate(to, from, next) {
    document.body.classList.add('is-scrolling')

    let scrollTimeout
    window.addEventListener('scroll', (event) => {
      clearTimeout(scrollTimeout)
      scrollTimeout = setTimeout(() => {
        document.body.classList.remove('is-scrolling')
      }, 66)
    })

    return next()
  },
}
