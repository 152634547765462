
import config from '@/site-config'

export default {
  name: 'MainMenuMobile',
  data() {
    const { routes } = config

    return {
      routes,
    }
  },
}
