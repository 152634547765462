import { render, staticRenderFns } from "./PageSectionCarrier.vue?vue&type=template&id=321519a8&scoped=true"
import script from "./PageSectionCarrier.vue?vue&type=script&lang=js"
export * from "./PageSectionCarrier.vue?vue&type=script&lang=js"
import style0 from "./PageSectionCarrier.vue?vue&type=style&index=0&id=321519a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "321519a8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Heading: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/Heading.vue').default,Paragraph: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/Paragraph.vue').default,Button: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/Button.vue').default,Section: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/Section.vue').default})
