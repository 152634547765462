
export default {
  name: 'Resource',
  props: {
    path: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      required: true,
      validator: (value) => ['pdf', 'doc'].includes(value),
    },
    meta: {
      type: String,
      default: null,
    },
  },
}
