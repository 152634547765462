
// import Logo from '@/assets/gd_logo.svg?inline'
// import LogoText from '@/assets/gd_logo_text.svg?inline'

export default {
  name: 'TheHeader',
  components: {
    // Logo,
    // LogoText,
  },
  data() {
    return {
      menuIsOpen: false,
    }
  },
  computed: {
    classes() {
      return {
        'menu-button--open': this.menuIsOpen,
      }
    },
  },
  methods: {
    toggleMenuIsOpen() {
      this.menuIsOpen = !this.menuIsOpen

      if (this.menuIsOpen) {
        document.documentElement.setAttribute('style', 'overflow: hidden')
      } else {
        document.documentElement.setAttribute('style', '')
      }
    },
  },
}
