import { render, staticRenderFns } from "./PageSectionIntro.vue?vue&type=template&id=f45e8e2c&scoped=true"
import script from "./PageSectionIntro.vue?vue&type=script&lang=js"
export * from "./PageSectionIntro.vue?vue&type=script&lang=js"
import style0 from "./PageSectionIntro.vue?vue&type=style&index=0&id=f45e8e2c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f45e8e2c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Heading: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/Heading.vue').default,Paragraph: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/Paragraph.vue').default,Section: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/Section.vue').default})
