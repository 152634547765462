import { render, staticRenderFns } from "./PageSectionResources.vue?vue&type=template&id=594c62c8&scoped=true"
import script from "./PageSectionResources.vue?vue&type=script&lang=js"
export * from "./PageSectionResources.vue?vue&type=script&lang=js"
import style0 from "./PageSectionResources.vue?vue&type=style&index=0&id=594c62c8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "594c62c8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Heading: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/Heading.vue').default,ResourceList: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/Resource/ResourceList.vue').default,Section: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/Section.vue').default})
