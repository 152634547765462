
export default {
  name: 'PageSectionResources',
  data() {
    return {
      resources: [
        // {
        //   title: 'Customer Application',
        //   type: 'pdf',
        //   path: '#',
        // },
        {
          title: 'W-9',
          type: 'pdf',
          path: '/W-9.pdf',
        },
        {
          title: 'Broker Motor Carrier Agreement',
          type: 'doc',
          path: '/broker-motor-carrier-agreement.doc',
        },
        {
          title: 'Broker Authority',
          type: 'pdf',
          path: '/authority.pdf',
        },
        {
          title: 'Surety Bond',
          type: 'pdf',
          path: '/surety-bond.pdf',
        },
        // {
        //   title: 'Contingency Cargo',
        //   type: 'pdf',
        //   path: '#',
        // },
      ],
    }
  },
}
