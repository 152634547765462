
import siteConfig from '@/site-config'

export default {
  name: 'PageSectionIntro',
  data() {
    return {
      siteConfig,
    }
  },
}
