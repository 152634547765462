import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6ea198ca&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=6ea198ca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ea198ca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/TheHeader/TheHeader.vue').default,PageSectionHero: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/PageSection/PageSectionHero.vue').default,PageSectionIntro: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/PageSection/PageSectionIntro.vue').default,PageSectionServices: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/PageSection/PageSectionServices.vue').default,PageSectionCarrier: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/PageSection/PageSectionCarrier.vue').default,PageSectionResources: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/PageSection/PageSectionResources.vue').default,PageSectionContact: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/PageSection/PageSectionContact.vue').default})
