
export default {
  name: 'Paragraph',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    content: {
      type: String,
      required: false,
      default: '',
    },
    titleAs: {
      type: String,
      default: 'h5',
      validator: (value) => ['h1', 'h2', 'h3', 'h4', 'h5'].includes(value),
    },
  },
}
